
import {
  defineComponent, reactive, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import CampoTelefone from '@/core/components/Tela/CampoTelefone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import Card from '@/core/components/Tela/Card.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import ServicoUsuario from '@/servicos/Sistema/ServicoUsuario';
import { IUsuario } from '@/models/Entidades/Sistema/Usuarios/IUsuario';

export default defineComponent({
  name: 'UsuarioModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    CampoTelefone,
    RequisicaoModal,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, apresentarMensagemSucesso, apresentarMensagemAlerta,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, defineTextoPadraoBotoes, apresentarBarraProgresso, ocultarBarraProgresso, sincronizarRegistro,
    } = useModalBase(props, emit);
    const servicoUsuario = new ServicoUsuario();
    telaBase.identificadorRecurso = 'CADASTRO_USUARIOS';

    const state = reactive({
      usuario: {} as IUsuario,
      listaArquivo: [],
      perfilUsuarioDb: 0,
      senhaDb: '',
      carrengadoImagem: false,
      apresentarUnicoPerfil: false,
      limparDadosRegistroAtividade: false,
    });

    function objetoInicial() {
      state.senhaDb = '';
      state.usuario = { codigo: 0, ativo: true } as IUsuario;
      state.usuario.senha = '';
    }

    async function enviarImagem(arquivo: any) {
      state.carrengadoImagem = true;
      const retornoArquivo = await servicoUsuario.enviarImagem(arquivo);
      if (retornoArquivo.status === EStatusRetornoRequisicao.Sucesso) {
        state.usuario.imagem = retornoArquivo.linkArquivo;
      } else {
        apresentarMensagemAlerta(retornoArquivo.mensagem);
      }
      state.carrengadoImagem = false;
    }

    async function removerImagem() {
      state.carrengadoImagem = true;
      const retornoArquivo = await servicoUsuario.removerImagem(state.usuario.imagem);
      if (retornoArquivo.status === EStatusRetornoRequisicao.Sucesso) {
        state.usuario.imagem = '';
      } else if (retornoArquivo.status === EStatusRetornoRequisicao.Alerta) {
        state.usuario.imagem = '';
        apresentarMensagemAlerta(retornoArquivo.mensagem);
      }
      state.carrengadoImagem = false;
    }

    async function preparaComportamentoTela() {
      if (props.operacao.codigoRegistro > 0) {
        state.usuario = await servicoUsuario.obter(props.operacao.codigoRegistro);
        state.senhaDb = state.usuario.senha;
        state.usuario.confirmacaoSenha = state.usuario.senha;
        telaBase.contratantesSelecionados = [];
        state.usuario.contratantes.forEach((usuarioContratante) => {
          telaBase.contratantesSelecionados.push(usuarioContratante.codigoContratante);
        });
      }
    }

    function validarCampos() {
      if (state.usuario.senha !== state.usuario.confirmacaoSenha) {
        apresentarMensagemAlerta('A senha e a senha de confimação não estão iguais!');
        return false;
      }

      let qtdMinuscula = 0;
      for (let i = 0; i < state.usuario.senha.length; i += 1) {
        if (state.usuario.senha[i].match(/[a-z]/)) {
          qtdMinuscula += 1;
        }
      }
      if (qtdMinuscula === 0) {
        apresentarMensagemAlerta('A senha deve ter pelo menos uma letra minúscula!');
        return false;
      }

      let qtdMaiuscula = 0;
      for (let i = 0; i < state.usuario.senha.length; i += 1) {
        if (state.usuario.senha[i].match(/[A-Z]/)) {
          qtdMaiuscula += 1;
        }
      }
      if (qtdMaiuscula === 0) {
        apresentarMensagemAlerta('A senha deve ter pelo menos uma letra maiuscula!');
        return false;
      }

      let qtdNumero = 0;
      for (let i = 0; i < state.usuario.senha.length; i += 1) {
        if (state.usuario.senha[i].match(/[0-9]/)) {
          qtdNumero += 1;
        }
      }
      if (qtdNumero === 0) {
        apresentarMensagemAlerta('A senha deve ter pelo menos um número!');
        return false;
      }

      let qtdSimbolos = 0;
      for (let i = 0; i < state.usuario.senha.length; i += 1) {
        if (state.usuario.senha[i].match(/\W/)) {
          qtdSimbolos += 1;
        }
      }
      if (qtdSimbolos === 0) {
        apresentarMensagemAlerta('A senha deve ter pelo menos um caractere especial!');
        return false;
      }
      return true;
    }

    async function salvar(salvarNovo: boolean) {
      if (state.senhaDb !== state.usuario.senha) {
        const validacao = validarCampos();
        if (!validacao) return;
      }

      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      if (state.usuario.codigo === 0) {
        apresentarBarraProgresso('Aguarde por favor, estamos cadastrando o usuário...');
        retorno = await servicoUsuario.incluir(state.usuario);
      } else {
        apresentarBarraProgresso('Aguarde por favor, estamos salvando as informações do usuário...');
        retorno = await servicoUsuario.alterar(state.usuario);
      }

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        ocultarBarraProgresso();

        if (state.usuario.codigo === 0) {
          sincronizarRegistro(retorno.codigoRegistro);
        } else {
          sincronizarRegistro(props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          const telaOperacao: ITelaOperacao = props.operacao;
          telaOperacao.codigoRegistro = 0;
          modalBase.computedOperacao = telaOperacao;
          await preparaComportamentoTela();
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        ocultarBarraProgresso();
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        defineTextoPadraoBotoes((props.operacao.codigoRegistro === 0));
        await preparaComportamentoTela();
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      state,
      salvar,
      objetoInicial,
      enviarImagem,
      removerImagem,
    };
  },
});
